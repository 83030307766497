import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/download1' // 设置默认路由为 '/home'
  },
  {
    path: '/edition1',
    name: 'edition1',
    component: () => import('@/views/h5/index.vue')
  },
  {
    path: '/edition2',
    name: 'edition2',
    component: () => import('@/views/h5/index.vue')
  },
  {
    path: '/edition3',
    name: 'edition3',
    component: () => import('@/views/h5/index.vue')
  },
  {
    path: '/edition4',
    name: 'edition4',
    component: () => import('@/views/h5/index.vue')
  },
  {
    path: '/andTutorials',
    name: 'andTutorials',
    component: () => import('@/views/tutorials/android.vue')
  },
  {
    path: '/iosTutorials',
    name: 'iosTutorials',
    component: () => import('@/views/tutorials/ios.vue')
  },
  {
    path: '/pc-download',
    name: 'pc-download',
    component: () => import('@/views/pc/download.vue')
  },
  {
    path: '/download1',
    name: 'download',
    component: () => import('@/views/pc/download.vue')
  },
  {
    path: '/download2',
    name: 'download2',
    component: () => import('@/views/pc/download2.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
